<template>
    <div class="home">
        <div
            class="logoImage-view"
            @click="openUrl"
        >
            <img
                class="logoImage"
                alt="beoga logo"
                src="@/assets/img/homescreen/Icon-name-Beoga-Alpha.png"
            >
        </div>
        <div class="route-btn-view home">
            <div class="subTitle">
                <h3 class="sub-text">
                    Statistics Calculator
                </h3>
            </div>
            <router-link
                :to="{name: 'summarize-data'}"
                tag="button"
                class="route-btn"
            >
                Summarize Data
            </router-link>
            <router-link
                :to="{name: 'distributions'}"
                tag="button"
                class="route-btn"
            >
                Distributions
            </router-link>
            <router-link
                :to="{name: 'confidence'}"
                tag="button"
                class="route-btn"
            >
                Confidence Intervals
            </router-link>
            <router-link
                :to="{name: 'hypothesis'}"
                tag="button"
                class="route-btn"
            >
                Hypothesis Testing
            </router-link>
            <router-link
                :to="{name: 'regression'}"
                tag="button"
                class="route-btn"
            >
                Correlation and Regression
            </router-link>
            <button
                class="route-btn"
                @click="showHelpModal()"
            >
                Calculator
            </button>
            <modal
                ref="modalCalculator"
                title="Calculator"
                style-class="modal-bottom"
                modal-type="general"
                is-calculator
            >
                <template #body>
                    <top-navigation-bar
                        title="Calculator"
                        is-calculator
                        right-nav="home"
                        @click="hideHelpModal()"
                    />
                    <calculator />
                </template>
            </modal>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import Calculator from '@/components/calculator.vue'
import Modal from '@/components/modal.vue'

export default {
    name: 'Home',
    components: {
        TopNavigationBar,
        Modal,
        Calculator
    },
    methods: {
        openUrl () {
            window.open('https://beoga.net/')
        },

        showHelpModal () {
            this.$refs.modalCalculator.show()
        },

        hideHelpModal () {
            this.$refs.modalCalculator.hide()
        },
    }
}
</script>
