import localStore from '@/store/localStore.js'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

// Summarize Data
const SummarizeData = () => import(/* webpackChunkName: "summarize-data" */ '@/views/summarize-data.vue')
// Distributions
const Distributions = () => import(/* webpackChunkName: "distributions" */ '@/views/distributions')
const NormalDistribution = () => import(/* webpackChunkName: "normal-distribution" */ '@/views/distributions/normal-distribution.vue')
const Binomial = () => import(/* webpackChunkName: "binomial" */ '@/views/distributions/binomial.vue')
const Tdistribution = () => import(/* webpackChunkName: "t-distribution" */ '@/views/distributions/t-distribution.vue')
const ChiSquare = () => import(/* webpackChunkName: "chi-square" */ '@/views/distributions/chi-square.vue')
const FDistribution = () => import(/* webpackChunkName: "f-distribution" */ '@/views/distributions/f-distribution.vue')
// Hypothesis Testing
const Hypothesis = () => import(/* webpackChunkName: "hypothesis-testing" */ '@/views/hypothesis.vue')
const OneMeanInput = () => import(/* webpackChunkName: "one-mean-input" */ '@/views/hypothesis/one-mean/input.vue')
const OneProportionInput = () => import(/* webpackChunkName: "one-proportion-input" */ '@/views/hypothesis/one-proportion/input.vue')
const OneVarianceInput = () => import(/* webpackChunkName: "one-variance-input" */ '@/views/hypothesis/one-variance/input.vue')
const TwoMeanChoice = () => import(/* webpackChunkName: "two-mean-choice" */ '@/views/hypothesis/two-mean/choice.vue')
const TwoMeanInput = () => import(/* webpackChunkName: "two-mean-input" */ '@/views/hypothesis/two-mean/input.vue')
const TwoProportionChoice = () => import(/* webpackChunkName: "two-proportion-choice" */ '@/views/hypothesis/two-proportion/choice.vue')
const TwoProportionInput = () => import(/* webpackChunkName: "two-proportion-input" */ '@/views/hypothesis/two-proportion/input.vue')
const TwoVarianceInput = () => import(/* webpackChunkName: "two-variance-input" */ '@/views/hypothesis/two-variance/input.vue')
// Confidence Intervals
const Confidence = () => import(/* webpackChunkName: "confidence" */ '@/views/confidence.vue')
const ChoiceFormula = () => import(/* webpackChunkName: "ci-choice-formula" */ '@/views/confidence/choice-formula.vue')
const ChoiceHelp = () => import(/* webpackChunkName: "ci-choice-help" */ '@/views/confidence/choice-help.vue')
const InputCI = () => import(/* webpackChunkName: "ci-input" */ '@/views/confidence/input.vue')
// Regression
const Regression = () => import(/* webpackChunkName: "regression" */ '@/views/regression.vue')
const TableOutput = () => import(/* webpackChunkName: "table-output" */ '@/views/regression/table-output.vue')
const More = () => import(/* webpackChunkName: "more" */ '@/views/regression/more.vue')
const Mci = () => import(/* webpackChunkName: "mci" */ '@/views/regression/final/m-ci.vue')
const Mht = () => import(/* webpackChunkName: "mht" */ '@/views/regression/final/m-ht.vue')
const Rht = () => import(/* webpackChunkName: "rht" */ '@/views/regression/final/r-ht.vue')
const Yci = () => import(/* webpackChunkName: "yci" */ '@/views/regression/final/y-ci.vue')

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { unRestricted: true }
    },
    // Summarize Data
    {
        path: '/summarize-data',
        name: 'summarize-data',
        component: SummarizeData,
        meta: { unRestricted: true }
    },
    // Distributions
    {
        path: '/distributions',
        name: 'distributions',
        component: Distributions,
        meta: { unRestricted: true }
    },
    {
        path: '/distributions/binomial',
        name: 'distributions/binomial',
        component: Binomial,
        meta: { unRestricted: true }
    },
    {
        path: '/distributions/normal-distribution',
        name: 'distributions/normal-distribution',
        component: NormalDistribution,
        meta: { unRestricted: true }
    },
    {
        path: '/distributions/t-distribution',
        name: 'distributions/t-distribution',
        component: Tdistribution,
        meta: { unRestricted: true }
    },
    {
        path: '/distributions/chi-square',
        name: 'distributions/chi-square',
        component: ChiSquare,
        meta: { unRestricted: true }
    },
    {
        path: '/distributions/f-distribution',
        name: 'distributions/f-distribution',
        component: FDistribution,
        meta: { unRestricted: true }
    },
    // Confidence Intervals
    {
        path: '/confidence',
        name: 'confidence',
        component: Confidence,
        meta: { unRestricted: true }
    },
    {
        path: '/confidence/ci-choice-formula',
        name: 'confidence/ci-choice-formula',
        component: ChoiceFormula,
        meta: { unRestricted: true }
    },
    {
        path: '/confidence/ci-choice-formula/ci-choice-help',
        name: 'confidence/ci-choice-help',
        component: ChoiceHelp,
    },
    {
        path: '/confidence/ci-choice-formula/ci-input',
        name: 'confidence/ci-input',
        component: InputCI,
    },
    // Hypothesis Testing
    {
        path: '/hypothesis',
        name: 'hypothesis',
        component: Hypothesis,
        meta: { unRestricted: true }
    },
    {
        path: '/hypothesis/one-mean-input',
        name: 'hypothesis/one-mean-input',
        component: OneMeanInput,
        meta: { unRestricted: true }
    },
    {
        path: '/hypothesis/one-proportion-input',
        name: 'hypothesis/one-proportion-input',
        component: OneProportionInput,
        meta: { unRestricted: true }
    },
    {
        path: '/hypothesis/one-variance-input',
        name: 'hypothesis/one-variance-input',
        component: OneVarianceInput,
        meta: { unRestricted: true }
    },
    {
        path: '/hypothesis/two-mean-choice',
        name: 'hypothesis/two-mean-choice',
        component: TwoMeanChoice,
        meta: { unRestricted: true }
    },
    {
        path: '/hypothesis/two-mean-choice/two-mean-input',
        name: 'hypothesis/two-mean-input',
        component: TwoMeanInput,
        meta: { unRestricted: true }
    },
    {
        path: '/hypothesis/two-proportion-choice',
        name: 'hypothesis/two-proportion-choice',
        component: TwoProportionChoice,
        meta: { unRestricted: true }
    },
    {
        path: '/hypothesis/two-proportion-choice/two-proportion-input',
        name: 'hypothesis/two-proportion-input',
        component: TwoProportionInput,
        meta: { unRestricted: true }
    },
    {
        path: '/hypothesis/two-variance-input',
        name: 'hypothesis/two-variance-input',
        component: TwoVarianceInput,
        meta: { unRestricted: true }
    },
    // Regression
    {
        path: '/regression',
        name: 'regression',
        component: Regression,
        meta: { unRestricted: true }
    },
    {
        path: '/regression/table-output',
        name: 'regression/table-output',
        component: TableOutput,
    },
    {
        path: '/regression/table-output/more',
        name: 'regression/more',
        component: More,
    },
    {
        path: '/regression/table-output/more/mci',
        name: 'regression/mci',
        component: Mci,
    },
    {
        path: '/regression/table-output/more/mht',
        name: 'regression/mht',
        component: Mht,
    },
    {
        path: '/regression/table-output/more/rht',
        name: 'regression/rht',
        component: Rht,
    },
    {
        path: '/regression/table-output/more/yci',
        name: 'regression/yci',
        component: Yci,
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach(async (to, from, next) => {
    var page = to.path.split('/')[1]
    if (!to.meta.unRestricted) {
        var storeAvailable = localStore.getStore(`${page}-data`)
        if (storeAvailable) {
            next() // allow
        } else {
            next(false) // stay
        }
    } else {
        next()
    }
})

export default router
