import _ from 'lodash'

var colors = {
    graphFillRed: 'rgba(200, 0, 0, 0.8)',
    graphFillWhite: 'rgba(255, 255, 255, 0.9)',
    lightWhite: '#f8f9fa',
    black: '#000000',
    white: '#ffffff',
    transparent: 'rgba(0, 0, 0, 0)'
}

const axisGrid = {
    lineColor: colors.black,
    lineWidth: 1,
    tickLength: 0,
    endOnTick: true,
    startOnTick: true
}

export const options = {
    chart: {
        backgroundColor: colors.white,
        borderColor: colors.lightWhite,
        borderWidth: 2,
        borderRadius: 5,
        spacingTop: 20,
        spacingRight: 40,
        height: 350,
    },
    plotOptions: {
        series: {
            color: colors.black,
        },
        area: {
            enableMouseTracking: false,
            fillColor: colors.graphFillRed,
        }
    },
    title: {
        text: ''
    },
    xAxis: _.assign({
        title: {
            text: ''
        },
    }, axisGrid),
    yAxis: _.assign({
        title: {
            text: ''
        },
        gridLineColor: colors.transparent,
    }, axisGrid),
    credits: {
        enabled: false
    },
}
