<template>
    <b-modal
        id="modalRef"
        ref="modalRef"
        :dialog-class="styleClass"
        hide-backdrop
        hide-footer
        :hide-header="isCalculator"
        :title="title"
    >
        <div v-if="modalType === 'general'">
            <div class="d-block text-center">
                <slot name="body" />
            </div>
        </div>
        <div v-else-if="modalType === 'hypothesis'">
            <div class="d-block divider">
                <slot name="formula" />
            </div>
            <div class="d-block divider">
                <slot name="image" />
            </div>
            <div class="d-block divider">
                <slot name="graph" />
            </div>
            <div class="d-block mt-2">
                <slot name="conclusion" />
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        modalType: {
            type: String,
            default: 'general',
        },
        isCalculator: {
            type: Boolean,
            default: false
        },
        title: {
            type: [String, Number],
            default: '',
        },
        styleClass: {
            type: String,
            default: '',
        },
    },

    methods: {
        show () {
            this.$refs.modalRef.show()
        },
        hide () {
            this.$refs.modalRef.hide()
        }
    },
}
</script>
