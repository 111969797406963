import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Highcharts
import { options } from '@/assets/highchart-options.js'
import Highcharts from 'highcharts'
import hcMore from 'highcharts/highcharts-more'
import bellcurve from 'highcharts/modules/histogram-bellcurve'
import HighchartsVue from 'highcharts-vue'

// Bootstrap
import '@/assets/scss/app.scss'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Highcharts.setOptions(options)

Vue.config.productionTip = false

bellcurve(Highcharts)
hcMore(Highcharts)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Highcharts, HighchartsVue)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
