<template>
    <div
        id="app"
        @keyup.enter="enterPressed($event)"
    >
        <router-view />
    </div>
</template>

<script>
export default {
    methods: {
        // dismisses keyboard on mobile
        enterPressed (e) {
            e.target.blur()
        }
    }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 650px;
  min-width: 420px;
  margin: auto;
  text-align: center;
  display: flex;
}
</style>
