
const getStore = (key) => {
    const store = JSON.parse(window.localStorage.getItem(key))
    return store
}

// Creates / Updates
const setStoreItem = (key, value) => {
    if (storageAvailable('localStorage')) {
        window.localStorage.setItem(key, JSON.stringify(value))
    } else {
        console.log('Error - localStorage full')
    }
}

const removeStoreItem = (key) => {
    window.localStorage.removeItem(key)
}

const clearStore = () => {
    window.localStorage.clear()
}

export default {
    getStore,
    setStoreItem,
    removeStoreItem,
    clearStore
}

// Handling
const storageAvailable = (type) => {
    var storage
    try {
        storage = window[type]
        var x = '__storage_test__'
        storage.setItem(x, x)
        storage.removeItem(x)
        return true
    } catch (e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0)
    }
}
