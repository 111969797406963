<template>
    <div class="wrapper">
        <div class="calc">
            <div class="top">
                <div class="result" />
                <div class="screen">
                    <span
                        id="screentext"
                        class="screentext animated"
                    /><span class="hints" />
                </div>
            </div>
            <div class="keyboard">
                <div class="crow">
                    <span class="cb cbac">
                        AC
                    </span>
                    <span
                        class="cb cbpar"
                        keyed="("
                    >(</span>
                    <span
                        class="cb cbpar"
                        keyed=")"
                    >)</span>
                    <span class="cb cbce">
                        ⬅︎
                    </span>
                </div>

                <div class="crow">
                    <span
                        class="cb cbnum"
                        keyed="(pi)"
                    >π</span>
                    <span
                        class="cb cbnum"
                        keyed="(e)"
                    >e</span>
                    <span class="cb cbans">
                        Ans
                    </span>
                    <span class="cb cbans" />
                </div>

                <div class="crow">
                    <span
                        class="cb cbfun"
                        key1="log10("
                    >log</span>
                    <span
                        class="cb cbfun"
                        key1="log("
                    >ln</span>
                    <span
                        class="cb cbop"
                        keyed="!"
                        char="!"
                    >!</span>
                    <span class="cb cbop" />
                </div>

                <div class="crow">
                    <span
                        class="cb cbfun"
                        key1="sqrt("
                    >√</span>
                    <span
                        class="cb cbop exp"
                        keyed="^2"
                        char="²"
                    >x²</span>
                    <span
                        class="cb cbop exp"
                        keyed="^3"
                        char="³"
                    >x³</span>
                    <span
                        class="cb cbop exp"
                        keyed="^"
                        char="^"
                    >xʸ</span>
                </div>

                <div class="crow">
                    <span
                        class="cb cbnum lighter"
                        keyed="7"
                    >7</span>
                    <span
                        class="cb cbnum lighter"
                        keyed="8"
                    >8</span>
                    <span
                        class="cb cbnum lighter"
                        keyed="9"
                    >9</span>
                    <span
                        class="cb cbop operands"
                        keyed="/"
                        char="÷"
                    >÷</span>
                </div>

                <div class="crow">
                    <span
                        class="cb cbnum lighter"
                        keyed="4"
                    >4</span>
                    <span
                        class="cb cbnum lighter"
                        keyed="5"
                    >5</span>
                    <span
                        class="cb cbnum lighter"
                        keyed="6"
                    >6</span>
                    <span
                        class="cb cbop operands"
                        keyed="*"
                        char="×"
                    >×</span>
                </div>

                <div class="crow">
                    <span
                        class="cb cbnum lighter"
                        keyed="1"
                    >1</span>
                    <span
                        class="cb cbnum lighter"
                        keyed="2"
                    >2</span>
                    <span
                        class="cb cbnum lighter"
                        keyed="3"
                    >3</span>
                    <span
                        class="cb cbop operands"
                        keyed="-"
                        char="-"
                    >-</span>
                </div>

                <div class="crow">
                    <span class="cb lighter" />
                    <span
                        class="cb cbnum lighter"
                        keyed="0"
                    >0</span>
                    <span
                        class="cb cbnum lighter"
                        keyed="."
                    >.</span>
                    <span
                        class="cb cbop operands"
                        keyed="+"
                        char="+"
                    >+</span>
                </div>

                <div class="crow">
                    <span class="cb calculate">
                        Calculate
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Calculator',
    props: {
        modalType: {
            type: String,
            default: 'general',
        },
    },
    mounted () {
        const $ = require('jquery')
        const { evaluate } = require('mathjs')

        $(function () {
            var expression = ''
            var expressionArray = []
            var screenArray = []
            var parentheses = 0
            var ansOnScreen = false
            var ans = null
            var error = false
            var inverted = false

            function defaults () {
                expression = ''
                expressionArray = []
                screenArray = []
                parentheses = 0
                ansOnScreen = false
                ans = null
                error = false
                inverted = false
                $('.result').html('')
                $('.screentext').html('')
                $('.hints').html('')
            }

            function adjustParentheses (num) {
                $('.hints').html(')'.repeat(num))
            }

            function writeToScreen (mode, text) {
                if (mode === 'append') {
                    if (error) {
                        screenArray = []
                    }
                    error = false
                    screenArray.push(text)
                } else if (mode === 'write') {
                    screenArray = [text]
                } else if (mode === 'delete') {
                    var popped = screenArray.pop()
                    if (/[(]$/g.test(popped)) {
                        parentheses > 0 ? parentheses-- : parentheses = 0
                        adjustParentheses(parentheses)
                    }
                }

                $('.screentext').html(screenArray.join(''))
            }

            function addToExpression (text) {
                expressionArray.push(text)
                expression += text
            }

            function removeFromExpression () {
                var count = expressionArray.pop().length
                expression = expression.slice(0, -count)
            }

            // ask for a result ----------------------------------------------------------
            $('.calculate').click(
                function () {
                    if (ansOnScreen) {
                        expressionArray = [ans]
                    }

                    addToExpression(')'.repeat(parentheses))

                    try {
                        evaluate(expressionArray.join('')).toPrecision(8)
                    } catch (e) {
                        error = true
                    }

                    if (error) {
                        defaults()
                        error = true
                        writeToScreen('write', 'Syntax Error')
                    } else {
                        $('.result').html($('.screentext').html().replace(/Ans/, ans) + ')'.repeat(parentheses) + ' =')
                        ans = evaluate(expressionArray.join('')).toPrecision(8)
                        writeToScreen('write', ans.toString().replace(/(\.0+$)|(0+$)/g, ''))
                        $('.hints').html('')

                        var el = $('#screentext')
                        var newone = el.clone(true)
                        el.before(newone)
                        $('.animated:last').remove()

                        ansOnScreen = true
                    }
                    parentheses = 0
                    expression = ''
                    expressionArray = []
                }
            )

            // clear the screen ----------------------------------------------------------
            $('.cbac').click(
                function () {
                    defaults()
                }
            )

            // add a number to the screen ------------------------------------------------
            $('.cbnum').click(
                function () {
                    var keyed = $(this).attr('keyed')

                    if (ansOnScreen) {
                        $('.result').html('Ans = ' + $('.screentext').html())
                        writeToScreen('write', '')
                        ansOnScreen = false
                    }

                    addToExpression(keyed)
                    writeToScreen('append', $(this).html())
                }
            )

            // add an operator to the screen if there's no other operator ----------------
            $('.cbop').click(
                function () {
                    var keyed = $(this).attr('keyed')
                    var char = $(this).attr('char')

                    if (ansOnScreen) {
                        $('.result').html('Ans = ' + $('.screentext').html())
                        writeToScreen('write', 'Ans')
                        expression = ans
                        expressionArray = [ans]
                        parentheses = 0
                        $('.hints').html('')
                        ansOnScreen = false
                    }

                    if ((/[/]$|[*]$/g.test(expression) && (keyed === '/' || keyed === '*'))) {
                        writeToScreen('write', $('.screentext').html().replace(/[÷]$|[×]$/g, char))
                        removeFromExpression()
                        addToExpression(keyed)
                    } else if (/[+]$|[-]$/g.test(expression) && (keyed === '+' || keyed === '-')) {
                        writeToScreen('write', $('.screentext').html().replace(/[+]$|[-]$/g, char))
                        removeFromExpression()
                        addToExpression(keyed)
                    } else {
                        writeToScreen('append', char)
                        addToExpression(keyed)
                    }

                    ansOnScreen = false
                }
            )

            // add a parentheses both to screen and to a global var ----------------------
            $('.cbpar').click(
                function () {
                    var keyed = $(this).attr('keyed')

                    if (ansOnScreen) {
                        writeToScreen('write', '')
                        ansOnScreen = false
                    }

                    addToExpression(keyed)
                    writeToScreen('append', keyed)

                    if (keyed === '(') {
                        parentheses++
                        adjustParentheses(parentheses)
                    } else if (keyed === ')') {
                        parentheses > 0 ? parentheses-- : parentheses = 0
                        adjustParentheses(parentheses)
                    }
                }

            )

            // add a function, change parentheses ----------------------------------------
            $('.cbfun').click(
                function () {
                    var key1 = $(this).attr('key1')
                    var key2 = $(this).attr('key2')

                    if (ansOnScreen) {
                        writeToScreen('write', '')
                        ansOnScreen = false
                    }

                    if (!inverted) {
                        addToExpression(key1)
                    } else {
                        addToExpression(key2)
                    }

                    writeToScreen('append', $(this).html() + '(')

                    parentheses++
                    adjustParentheses(parentheses)
                }
            )

            // append the old result to the expression-----------------------------------------
            $('.cbans').click(
                function () {
                    if (ansOnScreen) {
                        writeToScreen('write', '')
                        ansOnScreen = false
                    }
                    if (!/[Ans]$|[0-9]$|[π]$|[e]$/g.test($('.screentext').html())) {
                        addToExpression(ans.toString())
                        writeToScreen('append', 'Ans')
                    }
                }
            )

            // backspace -----------------------------------------------------------------------
            $('.cbce').click(
                function () {
                    if (ansOnScreen) {
                        writeToScreen('write', '')
                        ansOnScreen = false
                    }

                    if (expressionArray.length) {
                        removeFromExpression()
                        writeToScreen('delete', '')
                    }
                }
            )
        })
    },
}
</script>

<style lang="css">
* {
    font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
    font-weight: 900;
}

body {
    background: rgba(196, 192, 192, 0.2);
    font-weight: 300;
    margin: 0;
}

.wrapper {
    width: 100%;
}

.calc {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.top {
    max-width: 400px;
    width: 100%;
    background: #FFF9F9;
    color: #444;
    border-radius: 10px 10px 0 0;
}

.result {
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    font-size: 16px;
    margin: 0;
    padding: 7px 10px 0px 10px;
    text-align: left;
    opacity: 0.6;
    overflow: hidden;
}

.screen {
    width: 100%;
    height: 50px;
    border: 0;
    font-size: 20px;
    padding: 0px 20px 5px 5px;
    text-align: right;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    overflow: hidden;
}

.hints {
    opacity: 0.3;
}

.keyboard {
    max-width: 400px;
    width: 100%;
    margin-bottom: 50px;
}

.crow {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
    width: 100%;
    min-height: 50px;
    margin: auto;
}

.crow:last-child {
    border-radius: 0px 0px 10px 10px;
}

.cb {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    padding: 5px;
    background: rgba(221, 159, 159, 0.25);
    color: #444;
    cursor: pointer;
    outline: 1px solid rgba(136, 14, 79, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    transition: all 0.2s;
    font-weight: bolder;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.screentext {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.animated {
    position: relative;
    top: 0;
    animation: movetext1 .2s 1;
}

@keyframes movetext1 {
    0% {
        top: 100px;
    }
    100% {
        top: 0px;
    }
}

sup {
    vertical-align: text-top;
    font-size: 0.5em;
}

.inv {
    display: none;
}

.lighter {
    background-color: rgba(255, 235, 238, 1);
    font-size: 1.2em;
}

.exp {
    font-size: 1.2em;
}

.operands {
    background-color: rgba(231, 222, 222, 1);
    font-size: 1.4em;
}

.calculate {
    background-color: rgba(255, 235, 238, 1);
    font-size: 1.2rem;
    border: 1px solid rgba(0, 0, 255, 0.5);
    border-radius: 3px;
}
</style>
